export default function TabsHeader(props) {
  function setTab(tab) {
    if (!props.blocked) {
      props.setTab(tab);
    }
  }
  function setTabsMobile(tab) {
    if (!props.blocked) {
      switch(tab) {
        case 'DASHBOARD':
          props.setTab(0);
          break
        case 'RACES':
          props.setTab(1);
          break
        case 'PROFILE':
          props.setTab(2);
          break
        case 'ASSETS':
          props.setTab(3);
          break
        case 'SHOP':
          props.setTab(5);
          break
        case 'ARENA':
          props.setTab(4);
          break
        default:
          props.setTab(0);
          break
      }
    }
  }
  console.log(window.innerWidth)
  return (
    window.innerWidth > 500 ?
    <div className={`is-centered is-small is-fullwidth overflowVis has-text-weight-bold mb-2 ${window.innerWidth > 500 ? ' tabs' : '' }`}>
      <ul>
        <li className={props.currentTab === 0 ? "is-active" : ""}>
          <a onClick={() => setTab(0)} href="#dashboard">
            <span>DASHBOARD</span>
          </a>
        </li>
        <li className={props.currentTab === 1 ? "is-active" : ""}>
          <a onClick={() => setTab(1)} href="#races">
            <span>RACES</span>
          </a>
        </li>
        <li className={props.currentTab === 2 ? "is-active" : ""}>
          <a onClick={() => setTab(2)} href="#stats">
            <span>PROFILE</span>
          </a>
        </li>
        <li className={props.currentTab === 3 ? "is-active" : ""}>
          <a onClick={() => setTab(3)} href="#assets">
            <span>ASSETS</span>
          </a>
        </li>
        <li className={props.currentTab === 5 ? "is-active" : ""}>
          <a onClick={() => setTab(5)} href="#shop">
            <span>SHOP</span>
          </a>
        </li>
        <li className={props.currentTab === 4 ? "is-active" : ""}>
          <a onClick={() => setTab(4)} href="#arena">
            <span>ARENA</span>
          </a>
        </li>
      </ul>
    </div>
    :
    <div className="select is-rounded w100">
      <select className="w100" onChange={(event) => setTabsMobile(event.target.value)}>
        <option>DASHBOARD</option>
        <option>RACES</option>
        <option>PROFILE</option>
        <option>ASSETS</option>
        <option>SHOP</option>
        <option>ARENA</option>
      </select>
    </div>
  );
}
