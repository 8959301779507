import React from "react";
import { CryptoConnect, openModal } from "../components/CryptoConnect.js";
import shortenAddress from "../services/shortenAddress.js";
import Admin from "./Admin.js";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address,
      setAddress: props.setAddress,
      admin: false,
      currentTab: props.currentTab,
      setTab: props.setTab,
    };
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.setAdmin = this.setAdmin.bind(this);
  }

  async handleAddressChange(address) {
    await this.setState({
      address: address,
    });
    this.state.setAddress(address);
  }

  async setAdmin(bool) {
    await this.setState({
      admin: bool,
    });
  }

  //RETURN
  render() {
    return (
      <>
        <CryptoConnect
          handleAddressChange={this.handleAddressChange}
          setWeb3={this.props.setWeb3}
          chain="1"
          setAdmin={this.setAdmin}
        />
        <div className="flexContainer alignCenter">
          <div className="header flexBetween posRelative slight-opacity">
            <div className={`apeRidesText ml-3 op85 ${this.state.admin ? 'mobileNone' : ''}`}></div>
            <div className="is-flex is-align-items-center">
              {this.state.admin ? (
                <button
                  onClick={() => this.state.setTab(-1)}
                  className="button is-info is-outlined is-small mx-5 w-auto half-rounded has-text-weight-semibold"
                >
                  Admin
                </button>
              ) : (
                <></>
              )}
              <a
                href="https://mint.aperides.io"
                target="_blank"
                rel="noreferrer"
              >
                <button className="mobileNone button is-info is-outlined is-small mr-5 w-auto half-rounded has-text-weight-semibold">
                  Mint
                </button>
              </a>
              <a
                href="https://garage.aperides.io"
                target="_blank"
                rel="noreferrer"
              >
                <button className="mobileNone button is-info is-outlined is-small mr-5 w-auto half-rounded has-text-weight-semibold">
                  Garage
                </button>
              </a>
              <button
                className="mr-5 button is-dark is-small is-outlined w-auto half-rounded has-text-weight-semibold"
                onClick={openModal}
              >
                {this.state.address
                  ? "Connnected Address: " + shortenAddress(this.state.address)
                  : "Connect Wallet"}
              </button>
            </div>
          </div>
          <div className="App columns is-centered has-background-white-ter pr-4 pl-4 junglebg">
            <div className="container is-fullhd column is-11 has-background-white-bis mt-5 shadowBox is-flex is-justify-content-start is-flex-direction-column mh100 slight-opacity">
              {this.state.currentTab === -1 ? (
                <Admin setTab={this.state.setTab} />
              ) : (
                this.props.children
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Main;
