import { useEffect, useState } from "react";
import NFTCard from "../NFTCard";
import ErrorBoundary from "../ErrorBoundary";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import LoadingSpinner from "../LoadingSpinner";
import Overview from "./Overview";
import OverviewItem from "./OverviewItem";
import Fuel from "./Fuel";
import SecondaryLinks from "../SecondaryLinks";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
function renderSwitch(props) {
  switch (props.currentSection) {
    case 0:
      return <Overview assets={props.assets} />;
    case 1:
      return (
        <Fuel
          assets={props.assets}
          setAssets={props.setAssets}
          web3={props.web3}
          connectedAddress={props.connectedAddress}
        />
      );
    default:
      return (
        <>
          <div className="mt-3 w100">
            <OverviewItem data={props.assets.nfts[props.currentSection - 2]} />
          </div>
          {props.assets.nfts[props.currentSection - 2].nfts.map(
            (data, index) => {
              return (
                <ErrorBoundary key={index}>
                  <LazyLoadComponent>
                    <NFTCard apeCallback={console.log} nft={data} />
                  </LazyLoadComponent>
                </ErrorBoundary>
              );
            }
          )}
          {props.assets.nfts[props.currentSection - 2].total === 0 ? (
            <div className="w100 minh500 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
              <p className="is-size-5 has-text-weight-semibold is-uppercase">
                You do not own any{" "}
                {props.assets.nfts[props.currentSection - 2].contract.long}
              </p>
              <figure className="image is-32x32 mt-3">
                <img src="./sad.png" alt="sad" />
              </figure>
              <p className="is-size-7 has-text-weight-medium is-uppercase mt-5 mb-3">
                Buy one on a secondary marketplace
              </p>
              <SecondaryLinks
                data={props.assets.nfts[props.currentSection - 2]}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      );
  }
}

export default function AssetSection(props) {
  const [loadSection, setLoadSection] = useState(false);
  useEffect(() => {
    handleLoad();
  }, [props.currentSection]);

  async function handleLoad() {
    setLoadSection(true);
    await delay(250);
    setLoadSection(false);
  }

  return (
    <>
      {loadSection ? (
        <div className="maxh730 w99 overflow-scroll is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="maxh730 w99 overflow-scroll is-flex is-flex-direction-column is-justify-content-start is-align-items-start">
          <p className="is-size-4 has-text-weight-semibold mt-3 ml-2">
            {props.currentSection === 0
              ? "Assets Overview"
              : "Viewing " + props.sections[props.currentSection] + " Assets"}
          </p>
          <div className="is-flex is-flex-wrap-wrap maxh100 mww100">
            {renderSwitch(props)}
          </div>
        </div>
      )}
    </>
  );
}
