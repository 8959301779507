export default function FuelItem(props) {
  return (
    <div className="notification outlined-box mb-1 mww100 p-2 is-flex is-align-items-center is-justify-content-space-around is-flex-wrap-wrap">
      <div className="is-flex is-align-items-center is-justify-content-space-between w50">
        <div className="is-flex is-align-items-center w90">
          <div className="is-flex is-flex-direction-column is-align-items-start w70 ml-4">
            <p className="has-text-weight-medium is-size-5 has-text-left">
              APE RIDES $FUEL
            </p>
          </div>
        </div>
      </div>
      <div className="is-flex mr-5 is-justify-content-center is-align-items-center">
        <div className="is-flex is-flex-direction-column ml-6 ">
          <p className="has-text-weight-light is-size-7">
            TOTAL CLAIMABLE $FUEL
          </p>
          <p className="has-text-weight-medium is-size-5">
            {props.totals.claim.fuel} $FUEL
          </p>
        </div>
      </div>
    </div>
  );
}
