export default function Stats(props) {
  return (
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center h100">
      <figure className="image is-48x48 mb-2">
        <img src="./stats.png" alt="icon" />
      </figure>
      <p className="is-size-4 is-family-primary has-text-weight-medium">
        ApeRides Arena Profile & Stats
      </p>
      <p className="is-family-primary has-text-weight-normal">Coming Soon</p>
    </div>
  );
}
