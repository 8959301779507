export default function PfpItem(props) {
  return (
    <div className="notification outlined-box mb-1 mww100 p-2 is-flex is-align-items-center is-justify-content-space-evenly is-flex-wrap-wrap">
      <div className={`is-flex is-align-items-center is-justify-content-space-between ${props.tab==="description" ? 'w100 ml-1' : 'w60'}`}>
        <div className="is-flex is-align-items-center w90">
          <figure className="image is-64x64 ml-1">
            <img
              src={`https://storage.googleapis.com/aperides/assets/cLogos/pfp.png?small`}
              alt="fuel"
              className="half-rounded"
            />
          </figure>
          <div className="is-flex is-flex-direction-column is-align-items-start w70 ml-4">
            <p className="has-text-weight-light is-size-7">NFT TYPE</p>
            <p className="has-text-weight-medium is-size-5 has-text-left">
              COMPATIBLE NFT'S
            </p>
          </div>
        </div>
      </div>
      <div className={`is-flex ${props.tab==="description" ? "w100 is-justify-content-space-between" : "is-justify-content-center mr-5"} is-align-items-center`}>
        {props.tab === "overview" ? (
          <a
            href="#ADDCLAIMPATH"
            target="_blank"
            rel="noreferrer"
            className="t-d-none"
          >
            <button className="button is-outlined is-small mr-5 w-auto half-rounded has-text-weight-semibold">
              Claim
            </button>
          </a>
        ) : (
          <></>
        )}
        {props.tab === "description" ? (
          <>
          <div className="is-flex is-flex-direction-column ml-2 has-text-left">
            <p className="has-text-weight-light is-size-7">Any NFT that is compatible with Ape Rides is eligble for rewards</p>
            <p className="has-text-weight-light is-size-7">To view a list of all compatible collections, visit the Assets page</p>
          </div>
          <div className="is-flex is-flex-direction-column w20">
            <p className="has-text-weight-light is-size-7">REWARD FOR 1 NFT PER WEEK</p>
            <p className="has-text-weight-medium is-size-5">
              5.25 $FUEL
            </p>
          </div>
          </>
        ) : (
        <>
          <div className="is-flex is-flex-direction-column ml-6">
            <p className="has-text-weight-light is-size-7">TOTAL</p>
            <p className="has-text-weight-medium is-size-5">
              {props.totals.supported.nfts}
            </p>
          </div>
          <div className="is-flex is-flex-direction-column ml-6">
            <p className="has-text-weight-light is-size-7">TOTAL $FUEL</p>
            <p className="has-text-weight-medium is-size-5">
              {props.totals.supported.fuel}
            </p>
          </div>
        </>
        )}
      </div>
    </div>
  );
}
