// import RideItem from "../components/RideItem";
// import OverviewFuelItem from "../components/assets/OverviewFuelItem";
// import { useState, useEffect } from "react";
// import { baseApiUrl } from "../services/globals";
// import LoadingSpinner from "../components/LoadingSpinner"
import Fuel from "../components/assets/Fuel";

export default function Dashboard(props) {
  // const [races, setRaces] = useState(null)

  // async function getRaces() {
  //   const races = await fetch(
  //     `${baseApiUrl}/api/apeRacers/getRaces/false/0`
  //   ).then((res) => res.json());
  //   console.log(races)
  //   setRaces(races.races)
  // }

  // useEffect(() => {
  //   getRaces()
  // }, [])
  
  return (
    <div className="is-flex is-flex-direction-column is-justify-content-start is-align-items-center h100">
      <p className="is-size-2 is-family-primary has-text-weight-medium mt-4">
        APERIDES ARENA
      </p>
      <p className="is-family-primary has-text-weight-light mt-1">
        Welcome to the Ape Rides Arena Beta.<br></br>Collect $FUEL tokens,
        Enter races, Watch the action unfold, Collect your winnings, and Shop
        for rewards.<br></br>
      </p>
      <div className="mt-4 is-flex is-flex-direction-column is-justify-content-start is-align-items-start w90">
        <div className="w100 mb-5 mt-2">
          <Fuel
            assets={props.assets}
            setAssets={props.setAssets}
            web3={props.web3}
            connectedAddress={props.connectedAddress}
            dashboard={true}
          />
        </div>
        {/* <p className="is-size-5 is-family-primary has-text-weight-medium">
          RACES
        </p>
        <div className="w100 pt-2">
          {races 
            ?
            races.map((item) => {
              return <RideItem entered={false} race={item.data} key={item.id} raceid={item.id} assets={props.assets} web3={props.web3} />
            })
            :
            <LoadingSpinner />
          }

        </div>
        <p className="is-size-5 is-family-primary has-text-weight-medium mt-2">
          ENTERED RACES
        </p>
        <div className="w100 pt-2">

        </div> */}
      </div>
    </div>
  );
}
