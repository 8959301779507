// import { useEffect } from "react";
// import startRace from "../services/raceCanvas";

// export default function Arena(props) {

//   useEffect(() => {
//     startRace(document.getElementById('gameCanvas').getContext("2d"))
//   }, [])

//   return (
//     <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center h100">
//       <canvas id="gameCanvas" width="1200" height="600" className="mgt">
//         Your browser does not support HTML5 canvas
//       </canvas>
//     </div>
//   );
// }
export default function Arena(props) {
  return (
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center h100">
      <figure className="image is-48x48 mb-2">
        <img src="./arena.png" alt="icon" />
      </figure>
      <p className="is-size-4 is-family-primary has-text-weight-medium">
        ApeRides Arena
      </p>
      <p className="is-family-primary has-text-weight-normal">Coming soon</p>
    </div>
  );
}
