export const sections = [
  "Overview",
  "$FUEL",
  "Rides",
  "Ape Riders",
  "Ape Riders Club",
  "BAYC",
  "MAYC",
  "GACC",
  "DAW",
  "SAC",
  "TRAC",
  "LAYC",
  "PAYC",
  "JBAS",
  "BTFD",
  "BADDIES",
  "BTYC",
  "LIONS",
  "0xAPES",
  "0xMAYC",
  'AAS'
];
export const collectionImageSlugs = [
  "rides",
  "riders",
  "arc",
  "bayc",
  "mayc",
  "gacc",
  "daw",
  "sac",
  "trac",
  "layc",
  "payc",
  "jbas",
  "btfd",
  "baddies",
  "btyc",
  "lion",
  "xapes",
  "xmayc",
  'aas'
]; //add aperides and apeonriders
export const contractNames = [
  "RIDES",
  "RIDERS",
  "ARC",
  "BAYC",
  "MAYC",
  "GACC",
  "DAW",
  "SAC",
  "TRAC",
  "LAYC",
  "PAYC",
  "JBAS",
  "BTFD",
  "BADDIES",
  "BTYC",
  "LION",
  "0xAPE",
  "0xMAYC",
  "AAS"
];
export const contractLongNames = [
  "Ape Ride's",
  "Ape Rider's",
  "Ape Riders Club's",
  "Bored Ape Yacht Club's",
  "Mutant Ape Yacht Club's",
  "Grandpa Ape Country Club's",
  "Desperate Ape Wives",
  "Stoner Ape Club's",
  "Teen Rebel Ape Club's",
  "Lazy Ape Yacht Club's",
  "Pepe Ape Yacht Club's",
  "Japanese Born Ape Society's",
  "BTFD Rabbit's",
  "Baddie's",
  "Bored Ted Yacht Club's",
  "Lazy Lion's",
  "0xApes Trilogy's",
  "0xMayc Official's",
  "Angry Apes Society"
];
export const openseaSlugs = [
  "aperides",
  "aperiders",
  "ape-riders-club",
  "boredapeyachtclub",
  "mutant-ape-yacht-club",
  "grandpaapecountryclub",
  "desperate-ape-wives",
  "sac",
  "teenrebelapeclub",
  "official-layc",
  "paycgenesis",
  "japanesebornapesociety",
  "btfdrabbits",
  "baddiesofficial",
  "bored-ted-yacht-club",
  "lazy-lions",
  "0xapes-trilogy",
  "0xmayc-official",
  "angryapes-society-nft"
];
export const contractAddresses = [
  "0x1748b24faf9780b74e5a9f3feb41553e712e94aa",
  "0x380f0656a2cc032583bd26d4ffe29a27b6079050",
  "0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d",
  "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
  "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
  "0x4b103d07c18798365946e76845edc6b565779402",
  "0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e",
  "0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5",
  "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0",
  "0x1b1bff222999bcd6fd07b64d7880e6a95d54acaa",
  "0x2d0d57d004f82e9f4471caa8b9f8b1965a814154",
  "0x2120d19431e0dd49411e5412629f8e41a72cfabd",
  "0xe83dd605b70b47c8af86580bdd4fcb987ff36e60",
  "0x87212aa99f65611f6d67e0fbad76d06478753704",
  "0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2",
  "0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0",
  "0x22c08c358f62f35b742d023bf2faf67e30e5376e",
  "0xae63b956f7c77fba04e2eea59f7b8b2280f55431",
  "0xfa969c60a78195c631787d4585ba15a07578c979"
];

export const baseApiUrl = "https://api.aperides.io";
 // export const baseApiUrl = 'http://localhost:3000'
export const STAKING_DAY_CLAIM_WINDOW = 7;