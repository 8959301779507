export default function SecondaryLinks(props) {
  return (
    <div className="is-flex">
      <figure className="image is-24x24 ml-4">
        <a
          href={props.data.contract.links.etherscan}
          target="_blank"
          rel="noreferrer"
        >
          <img src="./etherscan.png" alt="etherscan" className="half-rounded" />
        </a>
      </figure>
      <figure className="image is-24x24 ml-4">
        <a
          href={props.data.contract.links.opensea}
          target="_blank"
          rel="noreferrer"
        >
          <img src="./opensea.png" alt="opensea" className="half-rounded" />
        </a>
      </figure>
      <figure className="image is-24x24 ml-4">
        <a
          href={props.data.contract.links.rarible}
          target="_blank"
          rel="noreferrer"
        >
          <img src="./rarible.png" alt="rarible" className="half-rounded" />
        </a>
      </figure>
      <figure className="image is-24x24 ml-4">
        <a
          href={props.data.contract.links.x2y2}
          target="_blank"
          rel="noreferrer"
        >
          <img src="./x2y2.png" alt="x2y2" className="half-rounded" />
        </a>
      </figure>
      <figure className="image is-24x24 ml-4">
        <a
          href={props.data.contract.links.gem}
          target="_blank"
          rel="noreferrer"
        >
          <img src="./gem.png" alt="gem" className="half-rounded" />
        </a>
      </figure>
    </div>
  );
}
