import revealRide from "../../../services/admin/revealRide";
import { useState } from "react";

export default function RevealRide(props) {
  const [rideId, setRideId] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  async function handleSubmit() {
    await revealRide(rideId, props.web3, setInProgress);
  }
  return (
    <div className="has-text-left">
      <p className="is-size-6 is-family-primary has-text-weight-semibold mt-4 mb-2">
        REVEAL RIDE
      </p>
      <article className="message is-link mb-2">
        <div className="message-body has-text-left">
          Reveal a ride if the automatic service has failed.{" "}
          <strong>DO NOT REVEAL RIDES THAT ARE UNMINTED.</strong> Add the ride
          ID of the ride you want to reveal below and press submit, a pop-up
          will confirm if the ride successefully revealed.
        </div>
      </article>
      <div className="field is-flex is-flex-direction-column is-align-items-start">
        <label className="label is-size-7 has-text-weight-medium">
          Ride ID
        </label>
        <div className="control">
          <input
            className="input"
            type="number"
            value={rideId}
            onChange={(event) => setRideId(event.target.value)}
          />
        </div>
      </div>
      <button
        className="button is-info is-outlined is-small w-auto half-rounded has-text-weight-medium mt-1"
        disabled={inProgress}
        onClick={() => {
          handleSubmit();
        }}
      >
        {inProgress ? "LOADING" : "SUBMIT"}
      </button>
    </div>
  );
}
