export default function shortenAddress(address) {
  let aLength = address.length;
  if (aLength !== 42) {
    return "";
  } else {
    return (
      "0x" +
      address.slice(2, 6).toUpperCase() +
      "..." +
      address.slice(aLength - 6, aLength).toUpperCase()
    );
  }
}
