import { SiweMessage } from "siwe";
import { baseApiUrl } from "./globals";

const domain = window.location.host;
const origin = window.location.origin;

async function createSiweMessage(address, statement) {
  const res = await fetch(
    `${baseApiUrl}/signature/nonce/${address.toLowerCase()}`
  ).then((res) => res.text());
  console.log(res);
  const message = new SiweMessage({
    domain,
    address: address,
    statement,
    uri: origin,
    version: "1",
    chainId: "1",
    nonce: res,
  });
  return message.prepareMessage();
}

export default async function signInWithEthereum(web3, message, callback) {
  const signer = web3.getSigner();
  const ethMessage = await createSiweMessage(
    await signer.getAddress(),
    message
  );
  console.log(ethMessage);
  signer
    .signMessage(ethMessage)
    .then(async (sig) => {
      console.log(`[SIGNED MESSAGE] ${message}:`, sig);
      let response = await fetch(`${baseApiUrl}/signature/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: ethMessage, signature: sig }),
      }).then((res) => res.json());
      callback(response);
    })
    .catch((error) => {
      console.log(error);
      callback({ message: error });
    });
}
