import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const NFTCard = ({ apeCallback, nft }) => {
  const sendApe = () => {
    apeCallback(nft);
  };

  return (
    <div className="flexc fwrap square30 mgt10">
      <LazyLoadImage
        alt={nft.name}
        src={nft.url}
        effect="blur"
        onClick={sendApe}
        width="96%"
        height="96%"
        placeholderSrc="./loading.jpeg"
        threshold="200"
      />
    </div>
  );
};

export default NFTCard;
