export default function OverviewFuelItem(props) {
  return (
    <div
      className={`is-flex-wrap-wrap notification outlined-box mb-1 mww100 p-2 is-flex is-align-items-center is-justify-content-space-between row-gap-20 ${
        props.tab === "main" ? "has-background-white-ter" : ""
      }`}
    >
      <div className="is-flex is-align-items-center is-justify-content-space-between w50">
        <div className="is-flex is-align-items-center w90">
          <figure className="image is-64x64 ml-1">
            <img
              src={`https://storage.googleapis.com/aperides/assets/cLogos/fuel.png`}
              alt="fuel"
              className="half-rounded"
            />
          </figure>
          <div className="is-flex is-flex-direction-column is-align-items-start ml-4">
            <p className="has-text-weight-light is-size-7">TOKEN</p>
            <p className="has-text-weight-medium is-size-5 has-text-left">
              APE RIDES $FUEL
            </p>
          </div>
        </div>
      </div>
        {/* {props.tab === "overview" || props.tab === "main" ? (
          <a
            href="#ADDCLAIMPATH"
            target="_blank"
            rel="noreferrer"
            className="t-d-none"
          >
            <button className="button is-outlined is-small mr-5 w-auto half-rounded has-text-weight-semibold">
              Claim
            </button>
          </a>
        ) : (
          <></>
        )} */}
        <div className="is-flex is-flex-direction-column">
          <p className="has-text-weight-light is-size-7">TOTAL CLAIMS</p>
          <p className="has-text-weight-medium is-size-5">
            {props.assets.profile.totalClaims}
          </p>
        </div>
        <div className="is-flex is-flex-direction-column ml-5 mr-6">
          <p className="has-text-weight-light is-size-7">BALANCE</p>
          <p className="has-text-weight-medium is-size-5">
            {props.assets.profile.balance}
          </p>
        </div>
      </div>
  );
}
