import { useState } from "react";
import OverviewFuelItem from "./OverviewFuelItem";
import ApeRidesItem from "./fuel/ApeRidesItem";
import PfpItem from "./fuel/PfpItem";
import FuelItem from "./fuel/FuelItem";
import claimDailyFuel from "../../services/claimDailyFuel";

function getDay() {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  return today.toDateString();
}

export default function Fuel(props) {
  const [claimed, setClaimed] = useState(props.assets.totals.claim.claimed);
  async function setAssetsBound(assets) {
    await props.setAssets(assets);
  }
  return (
    <div className="w100 is-flex mww100 pl-2 pr-3 is-flex is-flex-direction-column is-align-items-start">
      <p className="has-text-weight-light has-text-left mb-6">
        {props.dashboard ? '' : 'Claim your daily $FUEL tokens here, allowances are based on the total amount of compatible NFTs you hold.'}
      </p>
      <OverviewFuelItem tab="fuel" assets={props.assets} />
      <div className="is-centered w100 is-flex is-align-items-center is-justify-content-center">
        <div className={`is-flex w100 is-flex-direction-column ${props.dashboard ? 'is-align-items-center' : 'is-align-items-start'} column is-11`}>
          <div className="is-11 column">
            <p className="is-size-4 has-text-weight-semibold mt-6 mb-2">
              $FUEL Claim For {getDay()}
            </p>
            <ApeRidesItem tab="fuel" totals={props.assets.totals} />
            <div className="mt-2 w100">
              <PfpItem tab="fuel" totals={props.assets.totals} />
            </div>
            <div className="mt-2 w100">
              <FuelItem tab="fuel" totals={props.assets.totals} />
            </div>
            <button
              onClick={() => {
                claimDailyFuel(
                  props.web3,
                  props.assets,
                  setAssetsBound,
                  setClaimed,
                  props.connectedAddress
                );
              }}
              disabled={claimed}
              className={`button is-small w-auto half-rounded has-text-weight-medium fullButton mt-2 is-size-6 is-${
                claimed ? "danger" : "success"
              }`}
            >
              {claimed ? "WEEKLY $FUEL CLAIMED" : "CLAIM WEEKLY $FUEL"}
            </button>
          </div>
          <div className="is-11 column">
            <p className="is-size-4 has-text-weight-semibold mt-6 mb-2">
              $FUEL Rewards Breakdown
            </p>
            <ApeRidesItem tab="description" totals={props.assets.totals} />
            <div className="mt-2 w100">
              <PfpItem tab="description" totals={props.assets.totals} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
