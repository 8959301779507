import { baseApiUrl } from "../globals.js";
import signInWithEthereum from "../sendSignature.js";
import getConnectedAddress from "../getConnectedAddress.js";

export default async function removeFuelFromUser(useraddress, amount, web3, setInProgress) {
  await setInProgress(true);
  await signInWithEthereum(
    web3,
    `Ape Rides Arena: You are attempting to remove ${amount} $FUEL from ${useraddress}`,
    async (res) => {
      const address = await getConnectedAddress(web3);
      if (res.message && res.admin && res.nonce) {
        await fetch(`${baseApiUrl}/api/apeRacers/admin/removeFuel`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nonce: res.nonce,
            caller: address,
            address: useraddress,
            amount: amount
          }),
        }).then(async (res) => {
          const result = await res.json();
          console.log(result);
          if (result.revealed) {
            await setInProgress(false);
            window.alert(result.message);
            return;
          } else {
            await setInProgress(false);
            window.alert(result.message);
            return;
          }
        });
      } else {
        await setInProgress(false);
        window.alert("ERROR - SIGNATURE REJECTED");
      }
    }
  ).catch(async (e) => {
    await setInProgress(false);
  });
}
