import Dashboard from "../../pages/Dashboard";
import Races from "../../pages/Races";
import Stats from "../../pages/Stats";
import Assets from "../../pages/Assets";
import Arena from "../../pages/Arena";
import Admin from "../../pages/Admin";
import Shop from "../../pages/Shop";
import { useState, useEffect } from "react";
import getFormatedNfts from "../../services/getFormatedNfts";

export default function TabHandler(props) {
  const { connectedAddress, blocked, setBlocked, currentTab } = props;
  const [assets, setAssets] = useState([]);
  useEffect(() => {
    setBlocked(true);
    getFormatedNfts(connectedAddress ? connectedAddress : "").then(
      async (res) => {
        await setAssets(res);
        setBlocked(false);
      }
    );
  }, [connectedAddress, setBlocked]);

  if (!blocked) {
    switch (currentTab) {
      case 0:
        return <Dashboard assets={assets} web3={props.web3} setAssets={setAssets} />;
      case 1:
        return <Races assets={assets} web3={props.web3} />;
      case 2:
        return <Stats />;
      case 3:
        return (
          <Assets
            assets={assets}
            setAssets={setAssets}
            web3={props.web3}
            connectedAddress={props.connectedAddress}
          />
        );
      case 4:
        return <Arena />;
      case 5:
        return <Shop />;
      case -1:
        return <Admin web3={props.web3} />;
      default:
        return <p>ERROR</p>;
    }
  } else {
    return <p>LOADING..</p>;
  }
}
