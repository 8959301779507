export default function AssetMenu(props) {
  return (
    <aside className={`menu h100 has-background-white-ter ${window.innerWidth > 600 ? 'w30' : 'w100'}`}>
      { window.innerWidth > 600
      ?
      <ul className="menu-list">
        <li>
          <ul className="menu-list mb-0">
            {props.sections.map((key, index) => {
              return (
                <li className="menuHover" key={index}>
                  <a
                    onClick={() => props.setSection(index)}
                    className={
                      props.currentSection === index ? "is-active" : ""
                    }
                    href={"#assets/" + key}
                  >
                    <div className="px-2 is-flex is-justify-content-space-between">
                      <p><b>{key}</b></p>{" "}
                      <p>
                        {index > 1 ? props.assets.nfts[index - 2].total : ""}
                      </p>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
      :
      <div className="select is-rounded w100">
      <select className="w100" onChange={(event) => props.setSection(props.sections.indexOf(event.target.value))}>
      {
        props.sections.map((key, index) => {
          return <option key={index}>{key}</option>
        })
      }
      </select>
       </div>
      }
    </aside>
  );
}
