export default function OverviewItem(props) {
  return (
    <div className="is-flex-wrap-wrap notification outlined-box mb-1 mww100 p-2 is-flex is-align-items-center is-justify-content-space-between row-gap-20">
      <div className="is-flex is-align-items-center is-justify-content-space-between w40">
        <div className="is-flex is-align-items-center">
          <figure className="image is-64x64 ml-1">
            <img
              src={`https://storage.googleapis.com/aperides/assets/cLogos/${props.data.contract.bucketName}.png`}
              alt={props.data.contract.name}
              className="half-rounded"
            />
          </figure>
          <div className="is-flex is-flex-direction-column is-align-items-start w20 ml-4">
            <p className="has-text-weight-light is-size-7">SYMBOL</p>
            <p className="has-text-weight-medium is-size-5">
              {props.data.contract.symbol}
            </p>
          </div>
        </div>
        <div className="is-flex is-flex-direction-column ml-4">
          <p className="has-text-weight-light is-size-7">OWNED</p>
          <p className="has-text-weight-medium is-size-5">{props.data.total}</p>
        </div>
      </div>
      <div className="is-flex mr-5">
        {props.data.contract.bucketName === "rides" ? (
          <a
            href="https://mint.aperides.io"
            target="_blank"
            rel="noreferrer"
            className="t-d-none"
          >
            <button className="button is-outlined is-small mr-5 w-auto half-rounded has-text-weight-semibold">
              Mint
            </button>
          </a>
        ) : (
          <></>
        )}
        {props.data.contract.bucketName === "riders" ? (
          <a
            href="https://garage.aperides.io"
            target="_blank"
            rel="noreferrer"
            className="t-d-none"
          >
            <button className="button is-outlined is-small mr-5 w-auto half-rounded has-text-weight-semibold">
              Garage
            </button>
          </a>
        ) : (
          <></>
        )}
        <figure className="image is-24x24 ml-4">
          <a
            href={props.data.contract.links.etherscan}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="./etherscan.png"
              alt="etherscan"
              className="half-rounded"
            />
          </a>
        </figure>
        <figure className="image is-24x24 ml-4">
          <a
            href={props.data.contract.links.opensea}
            target="_blank"
            rel="noreferrer"
          >
            <img src="./opensea.png" alt="opensea" className="half-rounded" />
          </a>
        </figure>
        <figure className="image is-24x24 ml-4">
          <a
            href={props.data.contract.links.rarible}
            target="_blank"
            rel="noreferrer"
          >
            <img src="./rarible.png" alt="rarible" className="half-rounded" />
          </a>
        </figure>
        <figure className="image is-24x24 ml-4">
          <a
            href={props.data.contract.links.x2y2}
            target="_blank"
            rel="noreferrer"
          >
            <img src="./x2y2.png" alt="x2y2" className="half-rounded" />
          </a>
        </figure>
        <figure className="image is-24x24 ml-4">
          <a
            href={props.data.contract.links.gem}
            target="_blank"
            rel="noreferrer"
          >
            <img src="./gem.png" alt="gem" className="half-rounded" />
          </a>
        </figure>
      </div>
    </div>
  );
}
