import RevealRide from "../components/admin/nfts/revealRide";
import RegenAor from "../components/admin/nfts/regenAor";
import RegenAorFull from "../components/admin/nfts/regenAorFull"
import RegenPolaroid from "../components/admin/nfts/regenPolaroid"
import AddFuel from "../components/admin/arena/addFuel";
import RemoveFuel from "../components/admin/arena/removeFuel";
import CreateRace from "../components/admin/arena/createRace";

export default function Admin(props) {
  return (
    <div className="is-flex is-flex-direction-column is-justify-content-start is-align-items-center h100">
      <p className="is-size-3 is-family-primary has-text-weight-medium mt-4">
        APERIDES ADMIN
      </p>
      <div className="mt-2 is-flex is-flex-direction-column is-justify-content-start is-align-items-start w90">
        <span className="tag is-link is-light is-size-5 is-family-primary has-text-weight-semibold mt-4 mb-2 w100">
          APE RIDES ARENA
        </span>
        <CreateRace web3={props.web3} />
        <AddFuel web3={props.web3} />
        <RemoveFuel web3={props.web3} />
        <span className="tag is-link is-light is-size-5 is-family-primary has-text-weight-semibold mt-6 mb-2 w100">
          APE RIDES NFT'S
        </span>
        <RegenAor web3={props.web3} />
        <RegenAorFull web3={props.web3} />
        <RegenPolaroid web3={props.web3} />
        <RevealRide web3={props.web3} />
      </div>
    </div>
  );
}
