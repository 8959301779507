// import RideItem from "../components/RideItem";
// import { useState, useEffect } from "react";
// import { baseApiUrl } from "../services/globals";
// import LoadingSpinner from "../components/LoadingSpinner"

export default function Races(props) {
  // const [races, setRaces] = useState(null)

  // async function getRaces() {
  //   const races = await fetch(
  //     `${baseApiUrl}/api/apeRacers/getRaces/false/0`
  //   ).then((res) => res.json());
  //   console.log(races)
  //   setRaces(races.races)
  // }

  // useEffect(() => {
  //   getRaces()
  // }, [])
  
  return (
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center h100">
    <figure className="image is-48x48 mb-2">
      <img src="./bike.png" alt="icon" />
    </figure>
    <p className="is-size-4 is-family-primary has-text-weight-medium">
      ApeRides Arena Races
    </p>
    <p className="is-family-primary has-text-weight-normal">Coming Soon</p>
    </div>
    // <div className="is-flex is-flex-direction-column is-justify-content-start is-align-items-center h100">
    //   <p className="is-size-3 is-family-primary has-text-weight-medium mt-4">
    //     RACES
    //   </p>
    //   <div className="mt-4 is-flex is-flex-direction-column is-justify-content-start is-align-items-start w90">
    //     <div className="w100 pt-2">
    //       {races 
    //         ?
    //         races.map((item) => {
    //           return <RideItem entered={false} race={item.data} key={item.id} raceid={item.id} assets={props.assets} web3={props.web3} />
    //         })
    //         :
    //         <LoadingSpinner />
    //       }

    //     </div>
    //     {/** 
    //     <p className="is-size-5 is-family-primary has-text-weight-medium mt-2">
    //       ENTERED RACES
    //     </p>
    //     <div className="w100 pt-2">

    //     </div>
    //     */}
    //   </div>
    // </div>
  );
}
