import React from "react";
import Main from "./pages/Main.js";
import AwaitConnection from "./components/tabs/AwaitConnection.js";
import TabsHeader from "./components/tabs/TabsHeader.js";
import TabHandler from "./components/tabs/TabHandler.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentWindow: "Main",
      address: "",
      web3: null,
      currentTab: 0,
      blocked: true,
    };
    this.setTab = this.setTab.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.setBlocked = this.setBlocked.bind(this);
    this.setWeb3 = this.setWeb3.bind(this);
  }

  async handleAddressChange(address) {
    await this.setState({
      address: address,
    });
  }

  async setTab(tab) {
    await this.setState({
      currentTab: tab,
    });
  }

  async setWeb3(web3) {
    await this.setState({
      web3: web3,
    });
  }

  async setBlocked(bool) {
    await this.setState({
      blocked: bool,
    });
  }

  render() {
    switch (this.state.currentWindow) {
      case "Main":
        return (
          <>
            <Main
              window={this.state.currentWindow}
              setWeb3={this.setWeb3}
              setAddress={this.handleAddressChange}
              address={this.state.address}
              currentTab={this.state.currentTab}
              setTab={this.setTab}
            >
              {this.state.address === "" && !this.blocked ? (
                <AwaitConnection />
              ) : (
                <>
                  <TabsHeader
                    currentTab={this.state.currentTab}
                    setTab={this.setTab}
                    blocked={this.state.blocked}
                  />
                  <TabHandler
                    currentTab={this.state.currentTab}
                    connectedAddress={this.state.address}
                    blocked={this.state.blocked}
                    setBlocked={this.setBlocked}
                    web3={this.state.web3}
                  />
                </>
              )}
            </Main>
          </>
        );
      default:
        return <></>;
    }
  }
}

export default App;
