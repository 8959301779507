import {
  collectionImageSlugs,
  contractAddresses,
  contractNames,
  contractLongNames,
  openseaSlugs,
  STAKING_DAY_CLAIM_WINDOW,
} from "./globals";
import getNftsForAddress from "./getNftsForAddress";
import { baseApiUrl } from "./globals.js";

function getDay() {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  return today.toString();
}

let datetoday = getDay();
datetoday = datetoday.slice(0, datetoday.length - 34);

const RACES = [
  {
    name: "APE RIDES V2 CELEBRATION RACE",
    startTime: datetoday,
    entered: false,
  },
  {
    name: "APE RIDES CELEBRATION RACE 1",
    startTime: datetoday,
    entered: false,
  },
  {
    name: "APE RIDES CELEBRATION RACE 2",
    startTime: datetoday,
    entered: false,
  },
  { name: "APE RIDES CELEBRATION RACE 3", startTime: datetoday, entered: true },
  { name: "WEEKLY APERACE", startTime: datetoday, entered: true },
  { name: "JCOUTU`S WEEKLY RACE", startTime: datetoday, entered: true },
];

export default async function getFormatedNfts(address) {
  let array = createArray(contractAddresses.length);
  let totals = {
    aperides: 0,
    supported: 0,
  };
  const data = await getNftsForAddress(address, contractAddresses);
  const profile = await fetch(
    `${baseApiUrl}/api/apeRacers/getProfile/${address.toLowerCase()}`
  ).then((res) => res.json());
  data.forEach(async (item) => {
    try {
      let contractAddy = item.contract.address.toLowerCase();
      let nftTokenId = item.id.tokenId;
      let contractIndex = contractAddresses.indexOf(contractAddy);
      let nftImage = `https://storage.googleapis.com/nftobjects/${
        collectionImageSlugs[contractIndex]
      }/images/${parseInt(nftTokenId)}.png`;
      let tokenName =
        contractNames[contractIndex] + " #" + parseInt(nftTokenId);
      if (
        [
          "0x2120d19431e0dd49411e5412629f8e41a72cfabd",
          "0xe83dd605b70b47c8af86580bdd4fcb987ff36e60",
          "0x87212aa99f65611f6d67e0fbad76d06478753704",
          "0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2",
          "0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0",
          "0xfa969c60a78195c631787d4585ba15a07578c979"
        ].includes(contractAddy)
      ) {
        nftImage = item.media[0].thumbnail;
        totals.supported++;
      } else if (
        contractAddy === "0x1748b24faf9780b74e5a9f3feb41553e712e94aa"
      ) {
        nftImage = `https://storage.googleapis.com/aperidesnfts/images/${parseInt(
          nftTokenId
        )}.png`;
        totals.aperides++;
        // nftImage = item.media[0].gateway
      } else if (
        contractAddy === "0x380f0656a2cc032583bd26d4ffe29a27b6079050"
      ) {
        nftImage = `https://storage.googleapis.com/aperides/photobook/occupied/image/${parseInt(
          nftTokenId
        )}.png`;
        totals.aperides++;
        // nftImage = item.media[0].gateway
      } else if (
        contractAddy === "0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d"
      ) {
        nftImage = `https://storage.googleapis.com/aperidersclub/revealed/images/${parseInt(
          nftTokenId
        )}.png`;
        totals.aperides++;
        // nftImage = item.media[0].gateway
      } else {
        totals.supported++;
      }

      if (contractIndex !== -1) {
        array[contractIndex].nfts.push({
          tokenId: parseInt(nftTokenId),
          name: tokenName,
          url: nftImage,
          metadata: item.metadata,
        });
        array[contractIndex].total++;
      }
    } catch (errr) {
      console.warn("Error formatting NFT", errr, item);
    }
  });
  let supportedFuel = totals.supported * 0.75;
  let aperidesFuel = totals.aperides * 10;
  let totalFuel = (supportedFuel + aperidesFuel) * STAKING_DAY_CLAIM_WINDOW;
  totals = {
    claim: {
      fuel: totalFuel,
      claimed: false,
    },
    supported: {
      fuel: supportedFuel,
      nfts: totals.supported,
    },
    aperides: {
      fuel: aperidesFuel,
      nfts: totals.aperides,
    },
    account: {
      total: 0,
      claims: 0,
    },
  };
  let userProfile;
  if (profile.exists) {
    userProfile = profile.data;
    if (Date.now() - profile.data.lastClaimTime < (86400000 * STAKING_DAY_CLAIM_WINDOW)) {
      totals.claim.claimed = true;
    }
  } else {
    userProfile = {
      balance: 0,
      claimMultiplier: 0,
      lastClaimTime: 0,
      totalClaims: 0,
      wins: 0,
    };
  }
  console.log("[Formatted NFT's]: ", {
    totals: totals,
    nfts: array,
    races: RACES,
    profile: userProfile,
  });
  return { totals: totals, nfts: array, races: RACES, profile: userProfile };
}

function createArray(length) {
  let array = [];
  for (let i = 0; i < length; i++) {
    array.push({
      contract: {
        address: contractAddresses[i],
        symbol: contractNames[i],
        long: contractLongNames[i],
        bucketName: collectionImageSlugs[i],
        links: {
          opensea: `https://opensea.io/collection/${openseaSlugs[i]}`,
          looksrare: `https://looksrare.org/collections/${contractAddresses[i]}`,
          rarible: `https://rarible.com/collection/${contractAddresses[i]}`,
          x2y2: `https://x2y2.io/collection/${openseaSlugs[i]}/items`,
          gem: `https://www.gem.xyz/collection/${openseaSlugs[i]}`,
          etherscan: `https://etherscan.io/address/${contractAddresses[i]}`,
        },
      },
      nfts: [],
      total: 0,
    });
  }
  return array;
}
