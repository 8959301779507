import AssetMenu from "../components/assets/AssetMenu";
import AssetSection from "../components/assets/AssetSection";
import { sections } from "../services/globals.js";
import { React, useState } from "react";
export default function Assets(props) {
  const [section, setSection] = useState(0);
  return (
    <div className={`is-flex is-justify-content-start is-align-items-center h100 ${window.innerWidth > 600 ? '' : 'is-flex-direction-column'}`}>
      <AssetMenu
        currentSection={section}
        setSection={setSection}
        sections={sections}
        assets={props.assets}
      />
      <div className={`has-background-light h100 is-flex is-justify-content-start is-align-items-center ${window.innerWidth > 600 ? 'w70' : 'w100'}`}>
        <div className="has-background-light h96-5 w1 border-left"></div>
        <AssetSection
          currentSection={section}
          sections={sections}
          assets={props.assets}
          setSection={setSection}
          web3={props.web3}
          setAssets={props.setAssets}
          connectedAddress={props.connectedAddress}
        />
      </div>
    </div>
  );
}
