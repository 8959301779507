import React from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { providers, ethers } from "ethers";
import Web3 from "web3";
import signInWithEthereum from "../services/sendSignature";

class CryptoConnect extends React.Component {
  state = { chain: "1" };

  constructor(props) {
    super(props);
    this.state = {
      handleAddressChange: props.handleAddressChange,
      setAdmin: props.setAdmin,
    };
    this.connectMM = this.connectMM.bind(this);
    this.connectWC = this.connectWC.bind(this);
    this.connectCBW = this.connectCBW.bind(this);
  }

  async connectMM() {
    if (typeof window.ethereum !== "undefined") {
      console.log("window");
      //const chainID= await window.ethereum.request({ method: 'eth_chainId' });

      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const ethersweb3 = new ethers.providers.Web3Provider(window.ethereum);
      this.props.setWeb3(ethersweb3);

      window.ethereum.on("chainChanged", (chainId) => {});

      window.ethereum.on("accountsChanged", (accounts) => {});

      console.log(accounts[0]);

      let account = await accounts[0];

      await signInWithEthereum(
        ethersweb3,
        "Sign in to the Ape Rides Arena.",
        (res) => {
          if (res.message === true) {
            this.state.handleAddressChange(account);
          }
          if (res.admin === true) {
            this.state.setAdmin(true);
          }
          closeModal();
        }
      );
    } else {
      console.log("no window");
      return;
    }
  }

  async connectWC() {
    const provider = new WalletConnectProvider({
      infuraId: "99850c159db84348b7bdc4bf33a1e267",
    });

    provider.walletConnectProvider = undefined;
    await provider.enable().catch((error) => {
      if (error) {
        console.log("Modal Closed");
        return;
      }
    });
    const web3 = new Web3(provider);
    //  Wrap with Web3Provider from ethers.js
    const web3Provider = new providers.Web3Provider(provider);
    this.props.setWeb3(web3Provider);

    const accounts = await web3.eth.getAccounts();

    console.log(accounts[0]);

    let account = await accounts[0];

    signInWithEthereum(
      web3Provider,
      "Sign in to the Ape Rides Arena.",
      (res) => {
        if (res.message === true) {
          this.state.handleAddressChange(account);
        }
        if (res.admin === true) {
          this.state.setAdmin(true);
        }
        closeModal();
      }
    );

    //let chainID = await web3.eth.getChainId()

    provider.on("chainChanged", (chainId) => {});

    provider.on("accountsChanged", (accounts) => {});
  }

  async connectCBW() {
    const coinbaseWallet = new CoinbaseWalletSDK({
      appName: `Ape Rides`,
      appLogoUrl: `https://garage.aperides.io/logo192.png`,
      darkMode: true,
    });

    const ethereum = coinbaseWallet.makeWeb3Provider(
      "https://eth-mainnet.g.alchemy.com/v2/FeBF4O8PdhwNkQFNYAnjpWkjBuq74TMQ",
      1
    );

    const web3 = new Web3(ethereum);

    const web3Provider = new providers.Web3Provider(ethereum);
    this.props.setWeb3(web3Provider);

    await ethereum.enable().then((accounts) => {
      web3.eth.defaultAccount = accounts[0];
    });

    const accounts = await web3.eth.getAccounts();

    let account = await accounts[0];

    signInWithEthereum(
      web3Provider,
      "Sign in to the Ape Rides Arena.",
      async (res) => {
        if (res.message === true) {
          this.state.handleAddressChange(account);
        }
        if (res.admin === true) {
          this.state.setAdmin(true);
        }
        closeModal();
      }
    );

    ethereum.on("chainChanged", (chainId) => {});

    ethereum.on("accountsChanged", async (accounts) => {});
  }

  render() {
    return (
      <div id="walletPopUp" className="walletPopUpNone">
        <p className="sm-txt sdark-txt has-text-weight-semibold">
          CHOOSE YOUR PREFERRED WALLET
        </p>

        {this.state.chainError ? (
          <p className="xs-txt red-txt">
            Wrong Network - Please change to Ethereum
          </p>
        ) : (
          <p className="none"></p>
        )}

        <div className="flex walletFlex">
          <div onClick={this.connectMM} id="metamask" className="flexc wallet">
            <img src="metamask.png" alt="metamask" className="walletIcon"></img>
            <p className="xs-txt sdark-txt mgt has-text-weight-semibold">
              Metamask
            </p>
          </div>

          <div onClick={this.connectWC} className="flexc wallet">
            <img
              src="walletconnect.png"
              alt="walletconnect"
              className="walletIcon"
            ></img>
            <p className="xs-txt sdark-txt mgt has-text-weight-semibold">
              Wallet Connect
            </p>
          </div>

          <div
            onClick={this.connectCBW}
            className="flex-center flex-col wallet"
          >
            <img
              src="coinbasewallet.svg"
              alt="coinbasewallet"
              className="walletIcon"
            ></img>
            <p className="xs-txt sdark-txt mgt has-text-weight-semibold">
              Coinbase Wallet
            </p>
          </div>
        </div>
      </div>
    );
  }
}

function openModal() {
  document.getElementById("walletPopUp").style = "display: flex;";
}
function closeModal() {
  document.getElementById("walletPopUp").style = "display: none;";
}

export { CryptoConnect, openModal, closeModal };
