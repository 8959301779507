import removeFuelFromUser from "../../../services/admin/removeFuelFromUser";
import { useState } from "react";

export default function RemoveFuel(props) {
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  return (
    <div className="has-text-left">
      <p className="is-size-6 is-family-primary has-text-weight-semibold mt-4 mb-2">
        REMOVE $FUEL
      </p>
      <article className="message is-link mb-2">
        <div className="message-body has-text-left">
          Remove an amount of $FUEL from a users address.
          <strong>
            This is a production service and will edit the LIVE $FUEL database
          </strong>
          A pop-up will confirm if the $FUEL was removed.
        </div>
      </article>
      <div className="field is-flex is-align-items-start flex-gap is-flex-wrap-wrap">
        <div>
          <label className="label is-size-7 has-text-weight-medium mt-2">
            $FUEL Amount
          </label>
          <div className="control">
            <input
              className="input"
              type="number"
              value={amount}
              onChange={(event) => setAmount(event.target.value)} />
          </div>
        </div>
        <div>
          <label className="label is-size-7 has-text-weight-medium mt-2">
            Wallet address
          </label>
          <div className="control">
            <input 
              className="input"
              type="text"
              value={address}
              onChange={(event) => setAddress(event.target.value)} />
          </div>
        </div>
      </div>
      <button
        className="button is-info is-outlined is-small w-auto half-rounded has-text-weight-medium mt-1"
        onClick={() => {
          removeFuelFromUser(address, amount, props.web3, setInProgress);
        }}
      >
        {inProgress ? "LOADING" : "SUBMIT"}
      </button>
    </div>
  );
}
