import regenAorFull from "../../../services/admin/regenAorFull";
import { useState } from "react";

export default function RegenAorFull(props) {
  const [form, setForm] = useState({
    aorId: 0,
    rideId: 0,
    rideType: "",
    apeId: 0,
    apeAddress: "",
    minter: "",
    mintTime: "0000-00-00T00:00",
  });
  const [inProgress, setInProgress] = useState(false);
  return (
    <div className="has-text-left">
      <p className="is-size-6 is-family-primary has-text-weight-semibold mt-4 mb-2">
        REGENERATE AOR WITH METADATA
      </p>
      <span class="tag is-dark mb-2">
  NEW
</span>
      <article className="message is-link mb-2">
        <div className="message-body has-text-left">
          Regenerate an ape on ride image and metadata if the automatic service
          has failed.{" "}
          <strong>
            This is a production service and will edit the LIVE image and
            metadata of the associated NFT, make sure inputs are correct before
            submitting.
          </strong>
          You will need to view the etherscan transaction page to find some
          inputs needed below, navigate to the nft on opensea and find the mint
          event transaction. Add the all the information required below for the
          metadata of the ape on ride you want to regenerate below and press
          submit, a pop-up will confirm if the ape on ride successefully
          regenerated.
        </div>
      </article>
      <div className="field is-flex is-align-items-start flex-gap is-flex-wrap-wrap">
        <div>
          <label className="label is-size-7 has-text-weight-medium mt-2">
            Ape on Ride ID
          </label>
          <div className="control">
            <input
              className="input"
              type="number"
              value={form.aorId}
              onChange={(event) =>
                setForm({ ...form, aorId: event.target.value })
              }
            />
          </div>
        </div>
        <div>
          <label className="label is-size-7 has-text-weight-medium mt-2">
            Ride ID
          </label>
          <div className="control">
            <div className="control">
              <input
                className="input"
                type="number"
                value={form.rideId}
                onChange={(value) =>
                  setForm({ ...form, rideId: value.target.value })
                }
              />
            </div>
          </div>
          <div>
            <label className="label is-size-7 has-text-weight-medium mt-2">
              Ride Type
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={form.rideType}
                onChange={(value) =>
                  setForm({ ...form, rideType: value.target.value })
                }
              />
            </div>
          </div>
          <div>
            <label className="label is-size-7 has-text-weight-medium mt-2">
              Ape ID
            </label>
            <div className="control">
              <input
                className="input"
                type="number"
                value={form.apeId}
                onChange={(value) =>
                  setForm({ ...form, apeId: value.target.value })
                }
              />
            </div>
          </div>
          <div>
            <label className="label is-size-7 has-text-weight-medium mt-2">
              Ape Address
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={form.apeAddress}
                onChange={(value) =>
                  setForm({ ...form, apeAddress: value.target.value })
                }
              />
            </div>
          </div>
          <div>
            <label className="label is-size-7 has-text-weight-medium mt-2">
              Minter
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={form.minter}
                onChange={(value) =>
                  setForm({ ...form, minter: value.target.value })
                }
              />
            </div>
          </div>
          <div>
            <label className="label is-size-7 has-text-weight-medium mt-2">
              Mint Time
            </label>
            <div className="control">
              <input
                className="input"
                type="datetime-local"
                value={form.mintTime}
                onChange={(value) =>
                  setForm({ ...form, mintTime: value.target.value })
                }
              />
            </div>
          </div>
        </div>
        <button
          className="button is-info is-outlined is-small w-auto half-rounded has-text-weight-medium mt-1"
          onClick={() => {
            regenAorFull(form, props.web3, setInProgress);
          }}
        >
          {inProgress ? "LOADING" : "SUBMIT"}
        </button>
      </div>
    </div>
  );
}
