import OverviewItem from "./OverviewItem";
import OverviewFuelItem from "./OverviewFuelItem";

export default function Overview(props) {
  return (
    <div className="w100 is-flex mt-3 mww100 pl-2 pr-3 is-flex is-flex-direction-column">
      <OverviewFuelItem tab="overview" assets={props.assets} />
      {props.assets.nfts.map((data, index) => {
        return <OverviewItem key={index} data={data} />;
      })}
    </div>
  );
}
