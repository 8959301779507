import { alchemyWeb3 } from "./aperidesWeb3";

export default async function getNftsForAddress(
  _owner,
  contractAddresses = [],
  _pageKey = null,
  _array = []
) {
  const defCall = { owner: _owner, withMetadata: true };
  let array = _array;
  let response = {};

  if (_pageKey === null) {
    response = await alchemyWeb3.alchemy.getNfts({
      ...defCall,
      contractAddresses: contractAddresses,
    });
  } else {
    response = await alchemyWeb3.alchemy.getNfts({
      ...defCall,
      pageKey: _pageKey,
    });
  }

  array.push(...response.ownedNfts);

  if (response.pageKey) {
    await getNftsForAddress(_owner, contractAddresses, response.pageKey, array);
  } else {
    return array;
  }

  return array;
}
