import regenPolaroidNFT from "../../../services/admin/regenPolaroid";
import { useState } from "react";

export default function RegenPolaroid(props) {
  const [rideId, setRideId] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  return (
    <div className="has-text-left">
      <p className="is-size-6 is-family-primary has-text-weight-semibold mt-4 mb-2">
        REGENERATE POLAROID
      </p>
      <span class="tag is-dark mb-2">
  NEW
</span>
      <article className="message is-link mb-2">
        <div className="message-body has-text-left">
          Regenerate an ape on ride polaroid image if the automatic service has
          failed or if there was an error with layers.{" "}
          <strong>
            This is a production service and will edit the LIVE image of the
            associated NFT, make sure inputs are correct before submitting.
          </strong>{" "}
          Add the ID of the ape on ride you want to regenerate below and press
          submit, a pop-up will confirm if the ape on ride successefully
          regenerated.
        </div>
      </article>
      <div className="field is-flex is-flex-direction-column is-align-items-start">
        <label className="label is-size-7 has-text-weight-medium">
          Ape on Ride ID
        </label>
        <div className="control">
          <input
            className="input"
            type="number"
            value={rideId}
            onChange={(event) => setRideId(event.target.value)}
          />
        </div>
      </div>
      <button
        className="button is-info is-outlined is-small w-auto half-rounded has-text-weight-medium mt-1"
        onClick={() => {
          regenPolaroidNFT(rideId, props.web3, setInProgress);
        }}
      >
        {inProgress ? "LOADING" : "SUBMIT"}
      </button>
    </div>
  );
}
