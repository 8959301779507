export default function AwaitConnection() {
  return (
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center h100 is-full-width">
      <p className="is-size-2 is-family-primary has-text-weight-medium mt-6">
        APE RIDES ARENA
      </p>
      <p className="is-size-5 is-family-primary has-text-weight-light mt-4 mb-6">
        CONNECT A WALLET TO START RACING
      </p>
      <div className="is-flex is-justify-content-center">
        <figure className="image is-32x32 bikeArena">
          <img src="./bike.png" alt="bike" />
        </figure>
      </div>
      <hr className="has-background-dark" />
    </div>
  );
}
