import initRace from "../../../services/admin/initRace";
import { useState } from "react";

export default function CreateRace(props) {
  const [racers, setRacers] = useState(0);
  const [cost, setCost] = useState(0);
  const [name, setName] = useState('');
  const [type, setType] = useState(0);
  const [time, setTime] = useState('');
  const [inProgress, setInProgress] = useState(false);
  return (
    <div className="has-text-left">
      <p className="is-size-6 is-family-primary has-text-weight-semibold mt-4 mb-2">
        CREATE RACE
      </p>
      <article className="message is-link mb-2">
        <div className="message-body has-text-left">
          Create a new race.
          <strong>
            This is a production service and will add a race the LIVE race
            database
          </strong>
          a pop-up will confirm if the race was created successefully
        </div>
      </article>
      <div className="field is-flex is-align-items-start flex-gap is-flex-wrap-wrap">
        <div>
          <label className="label is-size-7 has-text-weight-medium mt-2">
            Cost
          </label>
          <div className="control">
            <input className="input" type="number" value={cost}
              onChange={(event) => setCost(event.target.value)} />
          </div>
        </div>
        <div>
          <label className="label is-size-7 has-text-weight-medium mt-2">
            Max Racers
          </label>
          <div className="control">
            <input className="input" type="number" value={racers}
              onChange={(event) => setRacers(event.target.value)} />
          </div>
        </div>
        <div>
          <label className="label is-size-7 has-text-weight-medium mt-2">
            Name
          </label>
          <div className="control">
            <input className="input" type="text" value={name}
              onChange={(event) => setName(event.target.value)} />
          </div>
        </div>
        <div>
          <label className="label is-size-7 has-text-weight-medium mt-2">
            Race Type
          </label>
          <div className="control">
            <input className="input" type="text" value={type}
              onChange={(event) => setType(event.target.value)} />
          </div>
        </div>
        <div>
          <label className="label is-size-7 has-text-weight-medium mt-2">
            Start Date & Time
          </label>
          <div className="control">
            <input className="input" type="datetime-local" value={time}
              onChange={(event) => setTime(event.target.value)} />
          </div>
        </div>
      </div>
      <button className="button is-info is-outlined is-small w-auto half-rounded has-text-weight-medium mt-1"
        onClick={() => {
          initRace(
            {cost: cost,
            maxRacers: racers,
            name: name,
            raceType: type,
            startTime: time
            },
            props.web3,
            setInProgress);
        }}
      >
        {inProgress ? "LOADING" : "SUBMIT"}
      </button>
    </div>
  );
}
