import { baseApiUrl } from "./globals.js";
import getConnectedAddress from "./getConnectedAddress.js";
import signInWithEthereum from "./sendSignature.js";

async function claimDailyFuelRequest(address, claim, nonce) {
  console.log(address);
  const fuelClaim = await fetch(
    `${baseApiUrl}/api/apeRacers/getDailyFuel/${address}/${claim}/${nonce}`
  ).then((res) => res.json());
  return fuelClaim;
}

export default async function claimDailyFuel(
  web3,
  assets,
  setAssets,
  setClaimed
) {
  await signInWithEthereum(
    web3,
    `Ape Rides Arena: You are claiming your daily allowance of ${assets.totals.claim.fuel} $FUEL`,
    async (res) => {
      console.log(res);
      let cAddress = await getConnectedAddress(web3);
      console.log(cAddress, assets.totals.claim.fuel, res.nonce);
      let fuelClaim = await claimDailyFuelRequest(
        cAddress,
        assets.totals.claim.fuel,
        res.nonce
      );

      console.log(fuelClaim);

      if (fuelClaim.success && res.message) {
        assets.profile.lastClaimTime = Date.now();
        assets.profile.balance =
          assets.profile.balance + assets.totals.claim.fuel;
        assets.profile.totalClaims = assets.profile.totalClaims + 1;
        window.alert(`CLAIMED ${assets.totals.claim.fuel} $FUEL`);
        await setClaimed(true);
        await setAssets(assets);
      } else {
        window.alert("$FUEL CLAIM FAILED");
      }
      console.log(assets);
    }
  );
}
